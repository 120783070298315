import AuthApiService from '@/services/AuthApiService';
import BreedingSessionsApiService from '@/services/models/BreedingSessionsApiService';
import ChildrenApiService from '@/services/models/ChildrenApiService';
import ChildRecordsApiService from '@/services/models/ChildRecordsApiService';
import ChildWeightRecordsApiService from '@/services/models/ChildWeightRecordsApiService';
import LittersApiService from '@/services/models/LittersApiService';
import ParentsApiService from '@/services/models/ParentsApiService';
import SharedUsersApiService from '@/services/models/SharedUsersApiService';
import SharedUserInvitesApiService from '@/services/models/SharedUserInvitesApiService';
import UsersApiService from '@/services/models/UsersApiService';

export const $api = {
    auth              : new AuthApiService(),
    breederSessions   : new BreedingSessionsApiService(),
    children          : new ChildrenApiService(),
    childRecords      : new ChildRecordsApiService(),
    childWeightRecords: new ChildWeightRecordsApiService(),
    litters           : new LittersApiService(),
    parents           : new ParentsApiService(),
    sharedUsers       : new SharedUsersApiService(),
    sharedUserInvites : new SharedUserInvitesApiService(),
    users             : new UsersApiService(),

};
