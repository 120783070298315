import AbstractApiService from '@/services/AbstractApiService';
import axios from 'axios';

export default class ApiService extends AbstractApiService {
    constructor(resource) {
        super(resource);
    }

    fetch(config = {}) {
        return new Promise((resolve, reject) => {
            axios.get(this.getUrl(), config)
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }

    get(id) {
        return new Promise((resolve, reject) => {
            if (!id) {
                reject(this.handleErrors(Error('Expected parameter "id" missing')));
            }
            axios.get(this.getUrl(id))
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }

    post(data = {}) {
        return new Promise((resolve, reject) => {
            axios.post(this.getUrl(), data)
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }

    put(id, data = {}) {
        return new Promise((resolve, reject) => {
            axios.put(this.getUrl(id), data)
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            axios.delete(this.getUrl(id))
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }
}
