// User
export const AUTHORIZE_ACTION    = 'AUTHORIZE';
export const USER_PROFILE_ACTION = 'USER_PROFILE';
export const LOGOUT_ACTION       = 'LOGOUT';
export const ACTIVATE_ACTION     = 'ACTIVATE';
export const REGISTER_ACTION     = 'REGISTER';

// UI
export const UI_TOGGLE_SIDEBAR_ACTION   = 'UI_TOGGLE_SIDEBAR';
export const UI_TOGGLE_DARK_MODE_ACTION = 'UI_TOGGLE_DARK_MODE';
export const UI_TOGGLE_ASIDE_ACTION     = 'UI_TOGGLE_ASIDE';
