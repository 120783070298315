export default class Abstract {
    service;

    constructor(service, obj = null) {
        this.service = service;
        if (obj !== null) {
            this._init(obj);
        }
    }

    _init(obj) {
        try {
            this['@context'] = obj['@context'] ?? null;
            this['@id']      = obj['@id'] ?? null;
            this['@type']    = obj['@type'] ?? null;
            this.id          = obj['id'] ?? null;
        } catch (e) {
            this.handleError('Failed to initialize entity');
        }
    }

    load(id) {
        return new Promise((resolve, reject) => {
            this.service.get(id)
                .then((response) => {
                    this._init(response);

                    resolve(true);
                })
                .catch((error) => {
                    this.handleError(error);

                    reject(false);
                });
        });
    }

    handleError(e) {
        console.log(e);
    }

    getContext() {
        return this['@context'];
    }

    getIri() {
        return this['@id'];
    }

    getType() {
        return this['@type'];
    }

    getId() {
        return this.id;
    }

    getIdFromIri(iri = null) {
        const str = (iri !== null) ? iri : ((this.getIri()) ? this.getIri() : null);
        const arr = (str !== null) ? str.split('/') : [];

        return (arr.length) ? arr[arr.length - 1] : null;
    }
}
