import Vue                                                                                      from 'vue';
import {$api}                                                                                   from '@/services';
import {USER_PROFILE_ACTION, AUTHORIZE_ACTION, LOGOUT_ACTION, REGISTER_ACTION, ACTIVATE_ACTION} from '@/store/action-types';
import {ERROR, LOADING_DONE, LOADING_START, SUCCESS, LOGOUT, AUTHORIZE, USER_PROFILE}           from '@/store/mutation-types';
import {SharedUserEntity}                                                                       from '@/entity/SharedUserEntity';

export const mutations = {
    [AUTHORIZE]   : (state, token) => {
        state.token = token;
    },
    [LOGOUT]      : (state) => {
        state.token   = '';
        state.status  = '';
        state.profile = [];
    },
    [USER_PROFILE]: (state, data) => {
        const sharedUser    = new SharedUserEntity();
        data.sharedUser     = sharedUser.getIdFromIri(data.sharedUser);
        state.profile       = data;
        state.profileLoaded = true;
    },
};

export const actions = {
    [AUTHORIZE_ACTION]   : ({commit, dispatch}, data) => {
        commit(LOADING_START);

        return new Promise((resolve, reject) => {
            Vue.axios.post(process.env.VUE_APP_API_AUTHORIZE_URL, data)
               .then(res => {
                   const token = 'Bearer ' + res.data.token;

                   commit(AUTHORIZE, token);
                   commit(SUCCESS);

                   dispatch(USER_PROFILE_ACTION);
                   resolve(res);
               })
               .catch(error => {
                   commit(ERROR, error);

                   dispatch(LOGOUT_ACTION);
                   reject(error);
               })
               .finally(() => {
                   commit(LOADING_DONE);
               });
        });
    },
    [ACTIVATE_ACTION]    : ({commit, dispatch}, data) => {
        return new Promise((resolve, reject) => {
            Vue.axios.post(process.env.VUE_APP_API_ACTIVATION_URL, data)
               .then(res => {
                   resolve(res);
               })
               .catch(err => {
                   reject(err);
               });
        });
    },
    [LOGOUT_ACTION]      : ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(LOGOUT);

            resolve(true);
        });
    },
    [USER_PROFILE_ACTION]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            $api.auth.getMe()
                .then(meResult => {
                    const userId = meResult.data.id;

                    return $api.users.get(userId);
                })
                .then(profileResult => {
                    commit(SUCCESS);
                    commit(USER_PROFILE, profileResult);

                    resolve(profileResult);
                })
                .catch(err => {
                    commit(ERROR);

                    dispatch(LOGOUT_ACTION);
                    reject(err);
                });
        });
    },
    [REGISTER_ACTION]    : ({commit, dispatch}, data) => {
        return new Promise((resolve, reject) => {
            Vue.axios.post(process.env.VUE_APP_API_REGISTER_URL, data)
               .then(res => {
                   resolve(res);
               })
               .catch(err => {
                   reject(err);
               });
        });
    }
};
