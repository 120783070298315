import Vue  from 'vue';
import Vuex from 'vuex';

import {actions}   from '@/store/actions';
import {mutations} from '@/store/mutations';
import {getters}   from '@/store/getters';

import storePlugins       from '@/plugins/storePlugins';
import saveToLocalStorage from '@/plugins/saveToLocalStorage';

Vue.use(Vuex);

const state = {
    token          : localStorage.getItem('user-token') || '',
    status         : '',
    success        : true,
    error          : false,
    loading        : false,
    profile        : {},
    profileLoaded  : false,
    sidebarShow    : 'responsive',
    sidebarMinimize: localStorage.getItem('sidebarMinimize') === 'true',
    asideShow      : localStorage.getItem('asideShow') === 'true',
    darkMode       : localStorage.getItem('darkMode') === 'true'
};

export default new Vuex.Store({
    // simpler adaptation of https://markus.oberlehner.net/blog/how-to-structure-a-complex-vuex-store/
    strict : process.env.NODE_ENV !== 'production',
    state,
    mutations,
    actions,
    getters,
    plugins: [storePlugins, saveToLocalStorage]
});
