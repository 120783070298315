import {
    UI_TOGGLE_SIDEBAR_ACTION,
    UI_TOGGLE_DARK_MODE_ACTION,
    UI_TOGGLE_ASIDE_ACTION,
} from '@/store/action-types';
import {
    UI_TOGGLE_SIDEBAR,
    UI_TOGGLE_DARK_MODE,
    UI_TOGGLE_ASIDE,
} from '@/store/mutation-types';

export const mutations = {
    [UI_TOGGLE_SIDEBAR]  : (state) => {
        state['sidebarMinimize'] = !state.sidebarMinimize;
        state['sidebarShow']     = [false, 'responsive'].includes(state.sidebarShow) ? true : 'responsive';
    },
    [UI_TOGGLE_DARK_MODE]: (state) => {
        state['darkMode'] = !state.darkMode;
    },
    [UI_TOGGLE_ASIDE]    : (state) => {
        state['asideShow'] = !state.asideShow;
    }
};

export const actions = {
    [UI_TOGGLE_SIDEBAR_ACTION]  : ({commit, dispatch}) => {
        commit(UI_TOGGLE_SIDEBAR);
    },
    [UI_TOGGLE_DARK_MODE_ACTION]: ({commit, dispatch}) => {
        commit(UI_TOGGLE_DARK_MODE);
    },
    [UI_TOGGLE_ASIDE_ACTION]    : ({commit, dispatch}) => {
        commit(UI_TOGGLE_ASIDE);
    }
};
