import ApiService from '@/services/ApiService';
import axios from 'axios';

export default class SharedUsersApiService extends ApiService {
    constructor() {
        super('shared_users');
    }

    getLitters(id) {
        return new Promise((resolve, reject) => {
            if (!id) {
                reject(this.handleErrors(Error('Expected parameter "id" missing')));
            }
            axios.get(this.getUrl(id, 'litters'))
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }
}
