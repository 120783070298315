import ErrorService from '@/services/ErrorService';

export default class AbstractApiService {
    baseUrl = process.env.VUE_APP_API_BASE_URL;
    resource;

    constructor(resource) {
        if (!resource) {
            throw new Error("Resource is not provided");
        }
        this.resource = resource;
    }

    getUrl(id = '', subResource = '') {
        const url = `${this.trimSlashes(this.baseUrl)}/${this.resource}/${id}/${subResource}`;
        return this.trimSlashes(url);
    }

    getSubResourceUrl(id = '', subResource = '') {
        return `${this.trimSlashes(this.baseUrl)}/${subResource}/${id}/${this.resource}`;
    }

    handleErrors(error) {
        ErrorService.onError(error);
        return error;
    }

    trimSlashes(value) {
        return value.replace(/\/+$/g, '');
    }
}
