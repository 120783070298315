import 'core-js/stable';
import Vue          from 'vue';
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js';
import App          from './App';
import VueGtm       from '@gtm-support/vue2-gtm';
import router       from '@/router/index';
import store        from '@/store/index';
import i18n         from './i18n.js';
import axios        from 'axios';
import VueAxios     from 'vue-axios';

import {cilPaw, cilDog, cilReload, cilChevronTop, cilChevronBottom, cilCalendar, cilPeople, cilUser, cilSettings, cilHome, cilMoon, cilSun, cilLockLocked, cilSpeech, cilLocationPin, cilList, cilSpeedometer, cibSkype, cilTrash, cilPencil, cilHospital, cilMedicalCross, cilBed} from '@coreui/icons';

import '@/plugins/mixins';
import {USER_PROFILE_ACTION}                                                                                                                                                                                                                                                        from '@/store/action-types';

Vue.config.performance = true;
Vue.use(CoreuiVuePro);
Vue.prototype.$log = console.log.bind(console);

Vue.use(VueAxios, axios);

Vue.use(VueGtm, {
    id             : process.env.VUE_APP_GTM_ID,
    defer          : false,
    compatibility  : false,
    nonce          : '2726c7f26c',
    enabled        : true,
    debug          : process.env.VUE_APP_GTM_DEBUG === 'true',
    loadScript     : true,
    vueRouter      : router,
    trackOnNextTick: false
});

const token = localStorage.getItem('user-token');
if (token) {
    axios.defaults.headers.common['Authorization'] = token;
}

router.beforeEach((to, from, next) => {
    const {authorize} = to.meta;

    if (to.matched.some(record => record.meta.authorize)) {
        if (authorize) {
            authorize.push('ROLE_ADMIN');
            store.dispatch(USER_PROFILE_ACTION)
                 .catch(err => {
                     if (!store.getters.isAuthenticated) {
                         next({
                             path : '/login',
                             query: {redirect: to.fullPath}
                         });
                     }
                     if (!authorize.includes(store.getters.role)) {
                         return next({path: '/login'});
                     }
                 });
        }

        next();
    } else {
        next();
    }
});

new Vue({
    el        : '#app',
    router,
    store,
    icons     : {cilPaw, cilDog, cilReload, cilChevronTop, cilChevronBottom, cilCalendar, cilPeople, cilUser, cilSettings, cilHome, cilMoon, cilSun, cilLockLocked, cilSpeech, cilLocationPin, cilList, cilSpeedometer, cibSkype, cilTrash, cilPencil, cilHospital, cilMedicalCross, cilBed},
    i18n,
    template  : '<App/>',
    components: {
        App
    }
});
