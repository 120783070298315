import ApiService from '@/services/ApiService';
import axios from 'axios';

export default class LittersApiService extends ApiService {
    constructor() {
        super('litters');
    }

    //getSharedUserLitters(sharedUserId) {
    //    return new Promise((resolve, reject) => {
    //        if (!sharedUserId) {
    //            reject(this.handleErrors(Error('Expected parameter "sharedUserId" missing')));
    //        }
    //        axios.get(this.getSubResourceUrl(sharedUserId, 'shared_users'))
    //             .then((response) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error) => {
    //                 this.handleErrors(error);
    //                 reject(this.handleErrors(error));
    //             });
    //    });
    //}

    //getUserLitters(userId) {
    //    return new Promise((resolve, reject) => {
    //        if (!userId) {
    //            reject(this.handleErrors(Error('Expected parameter "userId" missing')));
    //        }
    //        axios.get(this.getSubResourceUrl(userId, 'users'))
    //             .then((response) => {
    //                 resolve(response.data);
    //             })
    //             .catch((error) => {
    //                 this.handleErrors(error);
    //                 reject(this.handleErrors(error));
    //             });
    //    });
    //}
}
