import AbstractEntity        from '@/entity/AbstractEntity';
import SharedUsersApiService from '@/services/models/SharedUsersApiService';

export class SharedUserEntity extends AbstractEntity {
    constructor(obj = null) {
        super(new SharedUsersApiService(), obj);
    }

    _init(obj) {
        super._init(obj);
    }
}
