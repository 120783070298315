import ApiService from '@/services/ApiService';
import axios      from 'axios';

export default class SharedUserInvitesApiService extends ApiService {
    constructor() {
        super('shared_user_invites');
    }

    accept(id) {
        return new Promise((resolve, reject) => {
            axios.post(this.getUrl(id, 'accept'), {})
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }
}
