export default class ErrorService {
    static onError(error, message = '') {
        ErrorService.log('error', error, message);
    }

    static onWarn(error, message = '') {
        ErrorService.log('warn', error, message);
    }

    static onInfo(error, message = '') {
        ErrorService.log('info', error, message);
    }

    static onDebug(error, message = '') {
        ErrorService.log('debug', error, message);
    }

    static log(level, error, message) {
        const response = error.response;
        if (response) {
            if (response.status >= 400 && response.status < 405) {
                // Do something different?
            }
        }

        if (message === '') {
            message = error.message;
        }

        if (process.env.NODE_ENV !== 'production') {
            if (level === 'error') {
                console.error({message: message, error: error});
            } else if (level === 'warn') {
                console.warn({message: message, error: error});
            } else {
                console.log({message: message, error: error});
            }
        }
    }
}
