import Vue    from 'vue';
import Router from 'vue-router';
import store  from '../store';

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard      = () => import('@/views/Dashboard');
const Litters        = () => import('@/views/pages/Litters');
const Litter         = () => import('@/views/pages/Litter');
const LittersShared  = () => import('@/views/pages/LittersShared');
const Parents        = () => import('@/views/pages/Parents');
const Checklists     = () => import('@/views/pages/Checklists');
const VetVisits      = () => import('@/views/pages/VetVisits');
const FosterFamilies = () => import('@/views/pages/FosterFamilies');
const Settings       = () => import('@/views/pages/Settings');
const Profile        = () => import('@/views/pages/Profile');

// Admin Views
const AdminLitters = () => import('@/views/pages/admin/Litters');

// Views - Pages
const Page404  = () => import('@/views/pages/Page404');
const Page500  = () => import('@/views/pages/Page500');
const Login    = () => import('@/views/pages/Login');
const Activate = () => import('@/views/pages/Activate');
const Register = () => import('@/views/pages/Register');

Vue.use(Router);

const checkProfileLoaded = (to, from, next) => {
    function proceed() {
        if (store.state.profileLoaded) {
            next();
        }
    }

    if (!store.state.profileLoaded) {
        store.watch(
            (state) => state.profileLoaded,
            (value) => {
                if (value === true) {
                    proceed();
                }
            }
        );
    } else {
        proceed();
    }
};

export default new Router({
    mode           : 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior : () => ({y: 0}),
    routes         : configRoutes()
});

function configRoutes() {
    return [
        {
            path     : '/',
            redirect : '/dashboard',
            name     : 'Home',
            meta     : {
                authorize: ['ROLE_BREEDER', 'ROLE_FOSTER', 'ROLE_USER']
            },
            component: TheContainer,
            children : [
                {
                    path     : 'dashboard',
                    name     : 'Dashboard',
                    meta     : {
                        authorize: ['ROLE_BREEDER', 'ROLE_FOSTER', 'ROLE_USER']
                    },
                    component: Dashboard
                },
                {
                    path: 'admin',
                    component: {
                        render(c) {
                            return c('router-view');
                        }
                    },
                    children: [
                        {
                            path     : 'litters',
                            component: {
                                render(c) {
                                    return c('router-view');
                                }
                            },
                            children: [
                                {
                                    path     : '',
                                    name     : 'Litters',
                                    meta     : {
                                        authorize: []
                                    },
                                    component: AdminLitters
                                },
                            ]
                        }
                    ]
                },
                {
                    path     : 'litters',
                    component: {
                        render(c) {
                            return c('router-view');
                        }
                    },
                    children : [
                        {
                            path     : '',
                            name     : 'Litters',
                            meta     : {
                                authorize: ['ROLE_BREEDER']
                            },
                            component: Litters
                        },
                        {
                            path     : 'shared',
                            name     : 'LittersShared',
                            meta     : {
                                authorize: ['ROLE_BREEDER', 'ROLE_FOSTER', 'ROLE_USER']
                            },
                            component: LittersShared
                        },
                        {
                            path     : ':id',
                            name     : 'Litter',
                            component: Litter,
                            meta     : {
                                label    : 'Litter Details',
                                authorize: ['ROLE_BREEDER', 'ROLE_FOSTER', 'ROLE_USER']
                            },
                        }
                    ]
                },
                {
                    path     : 'parents',
                    name     : 'Parents',
                    meta     : {
                        authorize: ['ROLE_BREEDER']
                    },
                    component: Parents
                },
                {
                    path     : 'checklists',
                    name     : 'Checklists',
                    component: Checklists
                },
                {
                    path     : 'vet-visits',
                    name     : 'Vet Visits',
                    component: VetVisits
                },
                {
                    path     : 'foster-families',
                    name     : 'Foster Families',
                    meta     : {
                        authorize: ['ROLE_BREEDER']
                    },
                    component: FosterFamilies
                },
                {
                    path     : 'settings',
                    name     : 'Settings',
                    component: Settings
                },
                {
                    path       : 'profile',
                    name       : 'Profile',
                    beforeEnter: checkProfileLoaded,
                    component  : Profile,
                    meta       : {
                        authorize: ['ROLE_BREEDER', 'ROLE_FOSTER', 'ROLE_USER']
                    },
                }
            ]
        },
        {
            path     : '/login',
            name     : 'Login',
            component: Login,
            props    : true
        },
        {
            path     : '/activate',
            name     : 'Activate',
            component: Activate,
            props    : true
        },
        {
            path     : '/register',
            name     : 'Register',
            component: Register
        },
        {
            path     : '*',
            component: Page404
        }
    ];
}

