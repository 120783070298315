// General
export const ERROR         = 'ERROR';
export const SUCCESS       = 'SUCCESS';
export const LOADING_START = 'LOADING_START';
export const LOADING_DONE  = 'LOADING_DONE';

// User
export const AUTHORIZE    = 'AUTHORIZE';
export const USER_PROFILE = 'USER_PROFILE';
export const LOGOUT       = 'LOGOUT';

// UI
export const UI_TOGGLE_SIDEBAR   = 'UI_TOGGLE_SIDEBAR';
export const UI_TOGGLE_DARK_MODE = 'UI_TOGGLE_DARK_MODE';
export const UI_TOGGLE_ASIDE     = 'UI_TOGGLE_ASIDE';

