import ApiService from '@/services/ApiService';
import axios from 'axios';

export default class UsersApiService extends ApiService {
    constructor() {
        super('users');
    }

    getLitters(id) {
        return new Promise((resolve, reject) => {
            if (!id) {
                reject(this.handleErrors(Error('Expected parameter "id" missing')));
            }
            axios.get(this.getUrl(id, 'litters'))
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }

    getLitterParents(id) {
        return new Promise((resolve, reject) => {
            if (!id) {
                reject(this.handleErrors(Error('Expected parameter "id" missing')));
            }
            axios.get(this.getUrl(id, 'litter_parents'))
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }

    updatePassword(id, data) {
        return new Promise((resolve, reject) => {
            if (!id) {
                reject(this.handleErrors(Error('Expected parameter "id" missing')));
            }
            axios.put(this.getUrl(id, 'update_password'), data)
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }
}
