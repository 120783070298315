import AbstractApiService from '@/services/AbstractApiService';
import axios from 'axios';

export default class AuthApiService extends AbstractApiService {
    constructor() {
        super('auth');
    }

    getUrl(resource = '') {
        const url = `${this.baseUrl.replace(/\/$/g, '')}/${resource}`;
        return url.replace(/\/$/g, '');
    }

    getMe() {
        return new Promise((resolve, reject) => {
            axios.get(this.getUrl('me'))
                 .then((response) => {
                     resolve(response.data);
                 })
                 .catch((error) => {
                     reject(this.handleErrors(error));
                 });
        });
    }
}
