import Vue from 'vue';
import {
    AUTHORIZE,
    LOGOUT,
    UI_TOGGLE_SIDEBAR,
    UI_TOGGLE_DARK_MODE
}          from '@/store/mutation-types';

export default function saveToLocalStorage(store) {
    store.subscribe((mutation, state) => {

        if (mutation.type === AUTHORIZE) {
            const payload = mutation.payload;

            localStorage.setItem('user-token', payload);
            Vue.axios.defaults.headers.common['Authorization'] = payload;

        } else if (mutation.type === LOGOUT) {
            localStorage.removeItem('user-token');
            delete Vue.axios.defaults.headers.common['Authorization'];

        } else if (mutation.type === UI_TOGGLE_SIDEBAR) {
            const key   = 'sidebarMinimize';
            const value = state[key];

            localStorage.setItem(key, value);

        } else if (mutation.type === UI_TOGGLE_DARK_MODE) {
            const key   = 'darkMode';
            const value = state[key];

            localStorage.setItem(key, value);

        }
    });
}
