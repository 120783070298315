export const getters = {
    isAuthenticated: state => !!state.token,
    authStatus     : state => state.status,
    firstName      : state => state.profile['firstName'] ?? '',
    lastName       : state => state.profile['lastName'] ?? '',
    email          : state => state.profile['email'] ?? '',
    phone          : state => state.profile['phone'] ?? '',
    street         : state => state.profile['street'] ?? '',
    province       : state => state.profile['province'] ?? '',
    country        : state => state.profile['country'] ?? '',
    postalCode     : state => state.profile['postalCode'] ?? '',
    uid            : state => state.profile['@id'] ?? '',
    id             : state => state.profile['id'] ?? '',
    role           : state => state.profile['role'] ?? '',
    sharedUser     : state => state.profile['sharedUser'] ?? ''
};
