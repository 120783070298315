import {mutations as userMutations}                  from '@/store/modules/user';
import {mutations as uiMutations}                    from '@/store/modules/ui';
import {ERROR, LOADING_DONE, LOADING_START, SUCCESS} from '@/store/mutation-types';

export const mutations = {
    ...userMutations,
    ...uiMutations,
    [LOADING_START]: (state) => {
        state.loading = true;
    },
    [LOADING_DONE] : (state) => {
        state.loading = false;
    },
    [ERROR]        : (state, error) => {
        state.success = false;
        state.error   = error;
    },
    [SUCCESS]      : (state) => {
        state.success = true;
        state.error   = false;
    }
};
